import { render, staticRenderFns } from "./LiveMeet.vue?vue&type=template&id=018f70bf&scoped=true"
import script from "./LiveMeet.vue?vue&type=script&lang=js"
export * from "./LiveMeet.vue?vue&type=script&lang=js"
import style0 from "./LiveMeet.vue?vue&type=style&index=0&id=018f70bf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018f70bf",
  null
  
)

export default component.exports